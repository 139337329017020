define("portal-talentrh/pods/application/navbar/notification/survey/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ypvU7G4+",
    "block": "{\"symbols\":[\"@notification\"],\"statements\":[[10,\"a\"],[15,6,[30,[36,1],[\"pesquisa\",[35,0]],null]],[14,0,\"d-flex py-4\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"navbar__dropdown__item__body\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"navbar__dropdown__item__body__text\"],[12],[2,\"\\n        A pesquisa \"],[10,\"strong\"],[12],[1,[34,2]],[13],[2,\" recebeu novas respostas\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"navbar__dropdown__item__body__text--small\"],[12],[2,\"\\n      \"],[1,[30,[36,3],[[32,1,[\"createdAt\"]]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"link\",\"app-url\",\"message\",\"moment-from-now\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/application/navbar/notification/survey/template.hbs"
    }
  });
  _exports.default = _default;
});