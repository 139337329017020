define("portal-talentrh/pods/application/navbar/notification/item/component", ["exports", "ember-concurrency", "portal-talentrh/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['navbar__dropdown__item', 'list-group-item', 'p-0', 'bg-transparent'],
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    permission: Ember.inject.service(),
    isFeedbackRequest: Ember.computed.equal('notification.type', 'feedback-request'),
    isComplainingNotification: Ember.computed.equal('notification.type', 'complaining'),
    isMeeting: Ember.computed.equal('notification.type', 'meetingMinute'),
    type: Ember.computed.alias('notification.type'),
    avatar: Ember.computed('notification.{relatedUser.avatar,type,user.avatar}', function () {
      if (this.notification.type === 'created-user-solicitation') {
        return this.notification.user;
      }
      return this.notification.relatedUser;
    }),
    documentAlreadySigned: Ember.computed('notification.type', 'permission.docsToSign', function () {
      const docsToSign = this.permission.docsToSign;
      return this.notification.type === 'sent-signature-documents' && (!docsToSign || docsToSign.length === 0);
    }),
    isNotificationPdi: Ember.computed('notification.type', function () {
      return this.notification.type === 'pdi' || this.notification.type === 'pdi-action' || this.notification.type === 'pdi-action-expire-in' || this.notification.type === 'pdi-action-expired' || this.notification.type === 'evidence';
    }),
    isNotificationPde: Ember.computed('notification.type', function () {
      return this.notification.type === 'pde' || this.notification.type === 'pde-action' || this.notification.type === 'pde-evidence';
    }),
    isNotificationTraining: Ember.computed.equal('notification.type', 'training'),
    isNotificationMention: Ember.computed('notification.type', function () {
      let type = this.notification.type;
      return type === 'comment-mention' || type === 'post-mention';
    }),
    isNotificationFeedback: Ember.computed('notification.type', function () {
      let type = this.notification.type;
      return ['feedback', 'followup', 'feedback-approval'].includes(type);
    }),
    isNotificationProject: Ember.computed('notification.type', function () {
      let type = this.notification.type;
      return 'project' === type;
    }),
    isNotificationNewTask: Ember.computed('notification.type', function () {
      let type = this.notification.type;
      return 'newTask' === type;
    }),
    isNotificationUserAbsence: Ember.computed('notification.type', function () {
      let type = this.notification.type;
      return 'user-message' === type;
    }),
    isNotificationSharedPost: Ember.computed('notification.type', function () {
      let type = this.notification.type;
      return 'shared-post' === type;
    }),
    isBackupSolicitation: Ember.computed('notification.type', function () {
      let type = this.notification.type;
      return 'lgpd-backup-company-solicitation-available' === type;
    }),
    message: Ember.computed('notification.{message,type,decision.title,relatedUser.isFulfilled,user.isFulfilled}', 'typeMessages', function () {
      let typeMessage = this.typeMessages[this.notification.type];
      return typeMessage ? typeMessage(this.notification) : this.notification.message;
    }),
    init() {
      this._super(...arguments);
      this.typeAvatars = {
        'created-user-solicitation'(notification) {
          return Ember.get(notification, 'user.avatar');
        },
        forum(notification) {
          return Ember.get(notification, 'relatedUser.avatar');
        },
        decision(notification) {
          return Ember.get(notification, 'relatedUser.avatar');
        }
      };
      this.typeMessages = {
        'created-user-solicitation'(notification) {
          return `${Ember.get(notification, 'user.firstName')} ${Ember.get(notification, 'message')}`;
        },
        forum(notification) {
          return `${Ember.get(notification, 'relatedUser.firstName')} comentou no seu tópico`;
        },
        decision(notification) {
          return notification.message;
        }
      };
    },
    setAsRead: (0, _emberConcurrency.task)(function* () {
      if (this.notification.read) {
        return;
      }
      try {
        let {
          unreadCount
        } = yield this.ajax.post(`/notifications/${this.notification.id}/read`);
        Ember.set(this, 'isRead', true);
        Ember.set(this, 'unreadCount', unreadCount);
      } catch (e) {
        Ember.debug(e);
      }
    }).drop(),
    loadCarApproval: (0, _emberConcurrency.task)(function* () {
      let url;
      try {
        const carReservation = yield this.store.findRecord('car-reservation', this.notification.modelId);
        const car = yield Ember.get(carReservation, 'car');
        const needsApproval = car.get('needsManagerApproval');
        if (needsApproval) {
          url = `${_environment.default.appUrl['agenda']}car-reservation/requests/manager/${this.notification.modelId}/show`;
        } else {
          url = `${_environment.default.appUrl['agenda']}car-reservation/requests/admin/${this.notification.modelId}/show`;
        }
        return window.open(url);
      } catch (e) {
        Ember.debug(e);
      }
    }).drop(),
    actions: {
      redirect() {
        let url;
        let notification = this.notification;
        let type = this.type;
        let router = this.router;
        const newPerformanceModule = this.permission.companyConfig.featureFlags['newPerformanceModule'];
        if (type === 'task') {
          url = `${_environment.default.appUrl['projetos']}project/${notification.project}/task/${notification.modelId}`;
        } else if (type === 'carReservation') {
          url = `${_environment.default.appUrl['agenda']}car-reservation/calendar/${notification.modelId}/show`;
        } else if (type === 'employee-evaluation') {
          if (Ember.get(notification, 'employeeEvaluation')) {
            if (newPerformanceModule) {
              url = `${_environment.default.appUrl['performance']}evaluations/${notification.employeeEvaluation}/answer`;
            } else {
              url = `${_environment.default.appUrl['avd']}evaluation/answer/${notification.employeeEvaluation}`;
            }
          } else {
            if (newPerformanceModule) {
              url = `${_environment.default.appUrl['performance']}evaluations/myself`;
            } else {
              url = _environment.default.appUrl['avd'];
            }
          }
        } else if (type === 'invite') {
          url = `${_environment.default.appUrl['agenda']}invite`;
        } else if (type === 'appointment') {
          url = `${_environment.default.appUrl['agenda']}my-calendar/${notification.modelId}/show-appointment`;
          let modelId = Ember.get(notification, 'modelId');
          if (modelId) {
            let appointments = this.store.query('appointment', {
              id: modelId
            });
            if (appointments.length == 0) {
              url = '';
            }
          }
        }
        this.setAsRead.perform();
        switch (type) {
          case 'created-user-solicitation-to-manager':
            return router.transitionTo('portal-manager.request.index', {
              queryParams: {
                modelId: Ember.get(notification, 'modelId')
              }
            });
          case 'created-user-solicitation-to-hr':
            return router.transitionTo('human-resources.user-solicitations.index.show', Ember.get(notification, 'modelId'));
          case 'answered-user-solicitation':
            return router.transitionTo('feed.me.request');
          case 'forum':
            return router.transitionTo('forum.view-topic', [Ember.get(this, 'notification.topic.id')]);
          case 'meetingMinute':
            return router.transitionTo('company.minutes-of-meeting.index.view', Ember.get(this, 'notification.meetingMinute.id'));
          case 'decision':
            return router.transitionTo('company.decision.index');
          case 'announcement':
            return router.transitionTo('post.show', notification.get('post.id'));
          case 'task':
          case 'carResponsible':
          case 'carReservation':
          case 'employee-evaluation':
          case 'invite':
          case 'appointment':
            if (url !== '') {
              return window.open(url, '_blank');
            } else {
              return;
            }
          case 'complaining':
          case 'appointmentCanceled':
            return;
          case 'policy':
            return this.router.transitionTo('company.policy', {
              queryParams: {
                notAcceptedPolicies: true
              }
            });
          case 'avatar-image':
            return router.transitionTo('profile.edit');
          case 'sent-signature-documents':
            return router.transitionTo('feed');
          default:
            return;
        }
      }
    }
  });
  _exports.default = _default;
});