define("portal-talentrh/pods/application/navbar/notification/survey/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    message: Ember.computed('notification', 'notification.type', function () {
      if (Ember.get(this, 'notification.type') === 'survey') {
        return `${this.notification.titleQuiz}`;
      }
    }),
    link: Ember.computed('notification.', function () {
      const quizId = this.notification.quizId;
      return `result/${quizId}/general`;
    })
  });
  _exports.default = _default;
});