define("portal-talentrh/pods/application/navbar/component", ["exports", "ember-concurrency", "portal-talentrh/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    APP: {
      version
    }
  } = _environment.default;
  var _default = Ember.Component.extend({
    tagName: '',
    classNames: ['not-printable'],
    fetch: Ember.inject.service(),
    fullscreen: Ember.inject.service(),
    notification: Ember.inject.service(),
    permission: Ember.inject.service(),
    router: Ember.inject.service(),
    screen: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    userCookie: Ember.inject.service(),
    newVersion: null,
    openPersonalDataModal: false,
    company: Ember.computed.alias('session.company'),
    routeHome: Ember.computed.alias('permission.routeHome'),
    showOnlyFeed: Ember.computed.alias('permission.companyConfig.featureFlags.showOnlyFeed'),
    supportLink: Ember.computed('permission.companyConfig.subscription.sellType', function () {
      if (this.permission.companyConfig.subscription.sellType === 'convencional') {
        return "https://viasoft.movidesk.com/";
      } else if (this.permission.companyConfig.subscription.sellType === 'saas' || this.permission.companyConfig.subscription.sellType === 'embarcada') {
        return "https://atendimento.viasoft.com.br/form/3664/";
      }
      return "https://viasoft.movidesk.com/";
    }),
    init() {
      this._super(...arguments);
      if (this.session.data.keybkp) {
        Ember.set(this, 'canReturnToAccount', true);
      }
    },
    didInsertElement() {
      if (_environment.default.environment !== 'test') {
        this.initVersionSocketNotification();
      }
    },
    initVersionSocketNotification() {
      io.socket.on('version', message => {
        if (!message.number) {
          Ember.debug('O servidor não enviou uma versão válida para este frontend');
          return;
        }
        let versionNoSha = version.split('+')[0];
        let ember = versionNoSha.split('.').map(x => parseInt(x));
        let server = message.number.split('.').map(x => parseInt(x));
        if (server[0] > ember[0]) {
          Ember.set(this, 'newVersion', message.number);
        } else {
          if (server[1] > ember[1] && server[0] === ember[0]) {
            Ember.set(this, 'newVersion', message.number);
          } else {
            if (server[2] > ember[2] && server[1] === ember[1]) {
              Ember.set(this, 'newVersion', message.number);
            }
          }
        }
        if (this.newVersion) {
          Ember.set(this, 'openNewVersion', true);
        }
      });
    },
    loadLatestVersionChangeLog() {
      this.fetch.request('versions/latest', {
        host: _environment.default.apiUrl.conta,
        data: {
          module: 'portal-talentrh'
        }
      }).then(data => {
        Ember.set(this, 'latestVersion', data.version);
      });
    },
    superUserLogin: (0, _emberConcurrency.task)(function* () {
      try {
        this.session.set('data.keybkp', this.session.data.authenticated);
        yield this.session.authenticate('authenticator:super-user', {
          identification: this.userIdentifier
        });
        location.reload();
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    superUserReturn: (0, _emberConcurrency.task)(function* () {
      try {
        const {
          userAccountToken,
          tokenData
        } = this.session.data.keybkp;
        const {
          user
        } = tokenData;
        yield this.session.authenticate('authenticator:jwt', {
          token: userAccountToken,
          user: user.id,
          company: user.company
        });
        this.session.set('data.keybkp', undefined);
        Ember.set(this, 'canReturnToAccount', false);
        location.reload();
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    actions: {
      onClickLogo() {
        this.showOnlyFeed ? this.router.transitionTo('feed') : this.router.transitionTo(this.routeHome);
      },
      async logout() {
        await this.userCookie.deleteUserDbToken();
        await this.userCookie.invalidateAuthCookie();
      },
      toggleFullscreen() {
        this.fullscreen.toggle();
      },
      requestPersonalData() {
        Ember.set(this, 'openPersonalDataModal', !this.openPersonalDataModal);
      }
    },
    getPersonalDataReport: (0, _emberConcurrency.task)(function* () {
      let swalLoading = null;
      try {
        const headers = {};
        const authToken = this.session.token;
        if (authToken) {
          headers['Authorization'] = `Bearer ${authToken}`;
        }
        let url = `${_environment.default.apiUrl.social}/receipts/personalData`;
        swalLoading = this.swal.loading('Gerando o pdf...');
        const response = yield fetch(url, {
          method: 'GET',
          headers
        });
        const report = yield response.blob();
        const objectURL = URL.createObjectURL(report);
        const link = document.createElement('a');
        link.href = objectURL;
        link.download = 'NELA-Dados-Pessoais-LGPD.pdf';
        document.body.append(link);
        link.click();
        link.remove();
        swalLoading.close();
      } catch (e) {
        if (swalLoading) swalLoading.close();
        this.swal.catch(e);
      }
    }).drop()
  });
  _exports.default = _default;
});