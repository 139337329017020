define("portal-talentrh/pods/feed/me/index/goals/items/goal/component", ["exports", "@glimmer/component", "moment", "ember-concurrency-decorators"], function (_exports, _component, _moment, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const to = {
    notRated(unitType) {
      return unitType === 'lessThanOrEqual' || unitType === 'lessThan' ? Infinity : '';
    },
    calcPercent(value, valueAchieved, unitType, valueMin, valueMax) {
      if (unitType === 'lessThanOrEqual') {
        const newValueAchieved = valueAchieved < value ? value : valueAchieved;
        return Math.round(100 * value / newValueAchieved);
      } else if (unitType === 'greaterThanOrEqual') {
        const newValueAchieved = valueAchieved > value ? value : valueAchieved;
        return Math.round(newValueAchieved / value * 100);
      } else if (unitType === 'greaterThan') {
        if (valueAchieved > value) {
          return 100;
        } else if (valueAchieved === value) {
          return 99;
        } else {
          const newValueAchieved = valueAchieved > value ? value : valueAchieved;
          let percentage = Math.round(newValueAchieved / value * 100);
          return percentage < 99 ? percentage : 99;
        }
      } else if (unitType === 'lessThan') {
        if (valueAchieved < value) {
          return 100;
        } else if (valueAchieved === value) {
          return 99;
        } else {
          const newValueAchieved = valueAchieved < value ? value : valueAchieved;
          let percentage = Math.round(100 * value / newValueAchieved);
          return percentage < 99 ? percentage : 99;
        }
      } else if (unitType === 'between') {
        return valueAchieved >= valueMin && valueAchieved <= valueMax ? 100 : 0;
      } else {
        return 100;
      }
    },
    toNumber(value, unitType) {
      return value === null ? this.notRated(unitType) : Number(value);
    },
    getValues(goal) {
      const valueNumeric = this.toNumber(goal.value, goal.unitType);
      const valueAchievedNumeric = this.toNumber(goal.valueAchieved, goal.unitType);
      let valueMinNumeric;
      let valueMaxNumeric;
      if (goal.unitType === 'between') {
        valueMinNumeric = this.toNumber(goal.minValue, goal.unitType);
        valueMaxNumeric = this.toNumber(goal.maxValue, goal.unitType);
      }
      let percent = this.calcPercent(valueNumeric, valueAchievedNumeric, goal.unitType, valueMinNumeric, valueMaxNumeric);
      let percentNumber = percent;
      let percentLabel = `${percent}%`;
      let centerLabel = false;
      let processBarColor = 'bg-info';
      percent = percentLabel;
      if (goal.unitType === 'between') {
        percentLabel = percent === '100%' ? 'Meta atingida' : 'Meta não atingida';
        processBarColor = percent === '100%' ? 'bg-goal-progress-success' : 'bg-goal-progress-danger';
        percent = '100%';
        centerLabel = true;
      } else {
        if ((0, _moment.default)(goal.endAt).isBefore()) {
          processBarColor = percentNumber <= 99 ? 'bg-goal-progress-danger' : 'bg-goal-progress-success';
        } else {
          if (percentNumber <= 50) {
            processBarColor = 'bg-goal-progress-danger';
          } else {
            processBarColor = percentNumber > 50 && percentNumber <= 99 ? 'bg-goal-progress-warning' : 'bg-goal-progress-success';
          }
        }
      }
      return {
        ...goal,
        percent,
        percentLabel,
        processBarColor,
        centerLabel
      };
    },
    getQualitativeValues(goal) {
      const percentLabel = goal.valueAchieved === 'S' ? 'Meta atingida' : 'Meta não atingida';
      const processBarColor = goal.valueAchieved === 'S' ? 'bg-goal-progress-success' : 'bg-goal-progress-danger';
      return {
        ...goal,
        percent: '100%',
        processBarColor,
        percentLabel,
        centerLabel: true
      };
    }
  };
  let FeedMeIndexGoalsItemsGoalComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember.computed(), _dec10 = Ember._action, _dec11 = Ember._action, (_class = class FeedMeIndexGoalsItemsGoalComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intro", _descriptor, this);
      _initializerDefineProperty(this, "fetch", _descriptor2, this);
      _initializerDefineProperty(this, "permission", _descriptor3, this);
      _initializerDefineProperty(this, "openGoals", _descriptor4, this);
      _initializerDefineProperty(this, "closedGoals", _descriptor5, this);
      _initializerDefineProperty(this, "closedGoalsFiltered", _descriptor6, this);
      _initializerDefineProperty(this, "competenceSelected", _descriptor7, this);
      _initializerDefineProperty(this, "competenceClosedGoals", _descriptor8, this);
      this.loadGoalsInfo.perform();
    }
    get showLegend() {
      const goalClosedTab = document.getElementById('feed-me-goals-closed');
      console.log('goalClosedTab', goalClosedTab);
      return true;
    }
    *loadGoalsInfo() {
      try {
        const {
          user
        } = this.args;
        // Se não enviar um usuário por parâmetro pega o usuario logado.
        let userId = user ? user.id : this.permission.user.id;
        const data = yield this.fetch.request('/userGoals/findByUser', {
          data: {
            userId
          }
        });
        const isGoalsArrayEmpty = data.goals.length === 0;
        if (isGoalsArrayEmpty) {
          return;
        }
        const goals = data.goals.map(goal => {
          const {
            label,
            value,
            valueAchieved,
            percent,
            percentLabel,
            processBarColor,
            centerLabel
          } = goal.unit === 'qualitative' ? to.getQualitativeValues(goal) : to.getValues(goal);
          return {
            ...goal,
            label,
            value,
            valueAchieved,
            percent,
            percentLabel,
            processBarColor,
            centerLabel
          };
        });
        let userGoalIds = goals.map(userGoal => userGoal.id);
        let {
          goalAchieved
        } = yield this.fetch.request('/goalAchieved/goalAchievedByUserGoals', {
          data: {
            userGoalId: userGoalIds
          }
        });
        if (goalAchieved && goalAchieved.length) {
          goals.forEach(function (goal) {
            goal.achieved = [];
            goalAchieved.forEach(function (achieved) {
              if (achieved.userGoalId === goal.id) {
                achieved.unit = goal.unit;
                goal.achieved.push(achieved);
              }
            });
          });
        }
        this.openGoals = goals.filter(_ref => {
          let {
            endAt,
            userGoalActive: active
          } = _ref;
          return (0, _moment.default)().startOf('day').isSameOrBefore(endAt) && active;
        });
        this.closedGoals = goals.filter(_ref2 => {
          let {
            endAt,
            userGoalActive: active
          } = _ref2;
          return (0, _moment.default)().startOf('day').isAfter(endAt) || !active;
        });
        const competenceClosedGoals = this.closedGoals.reduce((entryMap, e) => entryMap.set(`${(0, _moment.default)(e.startAt).format('DD/MM/YYYY')} - ${(0, _moment.default)(e.endAt).format('DD/MM/YYYY')}`, [...(entryMap.get(`${(0, _moment.default)(e.startAt).format('DD/MM/YYYY')} - ${(0, _moment.default)(e.endAt).format('DD/MM/YYYY')}`) || []), e]), new Map());
        this.competenceClosedGoals = Array.from(competenceClosedGoals.keys());
        this.onChangeCompetenceClosedGoals();
      } catch (err) {
        Ember.debug(err);
      }
    }
    setProgressBarWidth(el) {
      el?.setAttribute('style', 'width:0');
      setTimeout(() => {
        el?.setAttribute('style', `width:${el.getAttribute('aria-valuenow')}`);
      }, 10);
    }
    onChangeCompetenceClosedGoals(selected) {
      const competenceDefault = this.competenceClosedGoals[0];
      this.competenceSelected = selected ?? competenceDefault;
      if (this.closedGoals) {
        this.closedGoalsFiltered = this.closedGoals.filter(closedGoal => {
          return `${(0, _moment.default)(closedGoal.startAt).format('DD/MM/YYYY')} - ${(0, _moment.default)(closedGoal.endAt).format('DD/MM/YYYY')}` === this.competenceSelected;
        });
        this.closedGoalsFiltered.forEach(goal => {
          const endAt = (0, _moment.default)(goal.endAt);
          const curAt = (0, _moment.default)();
          Ember.set(goal, 'closedNotAchieved', curAt.isAfter(endAt) && (goal.percent === 0 || goal.percent === '0%'));
        });
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intro", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "openGoals", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "closedGoals", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "closedGoalsFiltered", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "competenceSelected", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "competenceClosedGoals", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "showLegend", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "showLegend"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadGoalsInfo", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadGoalsInfo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setProgressBarWidth", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "setProgressBarWidth"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeCompetenceClosedGoals", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeCompetenceClosedGoals"), _class.prototype)), _class));
  _exports.default = FeedMeIndexGoalsItemsGoalComponent;
});