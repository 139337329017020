define("portal-talentrh/pods/forum/topic/index/create/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ForumTopicIndexCreateRoute extends Ember.Route {
    model() {
      return {
        topic: this.store.createRecord('topic', {
          category: this.modelFor('forum.topic.index')
        }),
        controllerIndex: this.controllerFor('forum.topic.index')
      };
    }
    deactivate() {
      this.controller.model.topic.rollbackAttributes();
      this.controllerFor('forum.topic.index').getFilters();
    }
  }
  _exports.default = ForumTopicIndexCreateRoute;
});