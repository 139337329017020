define("portal-talentrh/pods/view-team-member/performance-management/employee-evaluations/nine-box/quadrant/quadrant-modal/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let NineBoxQuadrantQuadrantModalComponent = (_dec = Ember.inject.service, _dec2 = Ember.computed.alias('permission.companyConfig.showJobFunction'), _dec3 = Ember.computed.alias('args.quadrant.usersQuadrant.firstObject'), _dec4 = Ember.computed.alias('args.quadrant.color'), _dec5 = Ember.computed.alias('args.nineBoxCycle.jobFunctions'), _dec6 = Ember.computed.alias('args.nineBoxCycle.jobRoles'), _dec7 = Ember.computed.alias('args.nineBoxCycle.departments'), _dec8 = Ember.computed.alias('args.nineBoxCycle'), _dec9 = Ember.computed('color'), _dec10 = Ember._tracked, (_class = class NineBoxQuadrantQuadrantModalComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "permission", _descriptor, this);
      _initializerDefineProperty(this, "showJobFunction", _descriptor2, this);
      _initializerDefineProperty(this, "userQuadrant", _descriptor3, this);
      _initializerDefineProperty(this, "color", _descriptor4, this);
      _initializerDefineProperty(this, "jobFunctions", _descriptor5, this);
      _initializerDefineProperty(this, "jobRoles", _descriptor6, this);
      _initializerDefineProperty(this, "departments", _descriptor7, this);
      _initializerDefineProperty(this, "nineBoxCycle", _descriptor8, this);
      _initializerDefineProperty(this, "axisOptions", _descriptor9, this);
    }
    get textColor() {
      let hex = this.color;
      if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
      }
      if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }
      if (hex.length !== 6) {
        return '#000000';
      }
      const r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);
      return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
    }
    get xAxisDescription() {
      let {
        cycles
      } = this.args;
      const xAxis = this.axisOptions.findBy('value', cycles.xAxis);
      if (!xAxis) return '';
      return xAxis.label;
    }
    get yAxisDescription() {
      let {
        cycles
      } = this.args;
      const yAxis = this.axisOptions.findBy('value', cycles.yAxis);
      if (!yAxis) return '';
      return yAxis.label;
    }
    get jobFunction() {
      let jobFunctionTitle = this.jobFunctions?.find(o => o.id === this.userQuadrant?.user?.jobFunction);
      return jobFunctionTitle?.title ?? 'Não informado';
    }
    get jobRole() {
      let jobRoleTitle = this.jobRoles?.find(o => o.id === this.userQuadrant?.user?.jobRole);
      return jobRoleTitle?.title ?? 'Não informado';
    }
    get department() {
      let departmentName = this.departments?.find(o => o.id === this.userQuadrant?.user?.department);
      return departmentName?.name ?? 'Não informado';
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "showJobFunction", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "userQuadrant", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "color", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "jobFunctions", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "jobRoles", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "departments", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "nineBoxCycle", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "textColor", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "textColor"), _class.prototype), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "axisOptions", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([{
        value: 'compentences',
        label: 'Competências'
      }, {
        value: 'results',
        label: 'Resultados'
      }]);
    }
  })), _class));
  _exports.default = NineBoxQuadrantQuadrantModalComponent;
});