define("portal-talentrh/pods/view-team-member/performance-management/employee-evaluations/nine-box/quadrant/user-avatar/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let NineBoxQuadrantUserAvatarComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember.computed.alias('args.quadrantUser'), _dec4 = Ember.computed.alias('quadrantUser.user'), _dec5 = Ember.computed.alias('args.nineBoxCycle.jobFunctions'), _dec6 = Ember.computed.alias('args.nineBoxCycle.jobRoles'), _dec7 = Ember.computed.alias('args.nineBoxCycle.departments'), _dec8 = Ember.computed.alias('args.nineBoxCycle.cycles.xAxis'), _dec9 = Ember.computed.alias('args.nineBoxCycle.cycles.yAxis'), _dec10 = Ember.computed.alias('user.jobFunction'), _dec11 = Ember.computed.alias('user.jobRole'), _dec12 = Ember.computed.alias('permission.companyConfig.showJobFunction'), _dec13 = Ember.computed('args.quadrantUser.{xAxisPercentage,xAxisPercentageFormatted,yAxisPercentage,yAxisPercentageFormatted}', 'axisOptions', 'departmentName', 'jobFunctionOrRoleDescription', 'user.{firstName,fullName,isLoaded,lastName}', 'xAxis', 'xAxisDescription', 'yAxis', 'yAxisDescription'), (_class = class NineBoxQuadrantUserAvatarComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "permission", _descriptor, this);
      _initializerDefineProperty(this, "axisOptions", _descriptor2, this);
      _initializerDefineProperty(this, "quadrantUser", _descriptor3, this);
      _initializerDefineProperty(this, "user", _descriptor4, this);
      _initializerDefineProperty(this, "jobFunctions", _descriptor5, this);
      _initializerDefineProperty(this, "jobRoles", _descriptor6, this);
      _initializerDefineProperty(this, "departments", _descriptor7, this);
      _initializerDefineProperty(this, "xAxis", _descriptor8, this);
      _initializerDefineProperty(this, "yAxis", _descriptor9, this);
      _initializerDefineProperty(this, "jobFunction", _descriptor10, this);
      _initializerDefineProperty(this, "jobRole", _descriptor11, this);
      _initializerDefineProperty(this, "showJobFunction", _descriptor12, this);
    }
    get getAvatar() {
      return this.user.avatarUrl ? this.user.avatarUrl : '/images/user-sem-imagem.png';
    }
    get jobFunctionDescription() {
      const jobFunctionDescription = this.jobFunctions?.find(o => o.id === this.user?.jobFunction)?.title;
      return `Função: ${jobFunctionDescription ?? 'Não informado'}`;
    }
    get jobRoleDescription() {
      const jobRoleDescription = this.jobRoles?.find(o => o.id === this.user?.jobRole)?.title;
      return `Cargo: ${jobRoleDescription ?? 'Não informado'}`;
    }
    get jobFunctionOrRoleDescription() {
      if (this.showJobFunction) {
        return this.jobFunctionDescription;
      } else {
        return this.jobRoleDescription;
      }
    }
    get departmentName() {
      const departmentName = this.departments?.find(o => o.id === this.user?.department)?.name;
      return `Departamento: ${departmentName ?? 'Não informado'}`;
    }
    get popoverContent() {
      const xAxisDescription = this.axisOptions.findBy('value', this.xAxis);
      const yAxisDescription = this.axisOptions.findBy('value', this.yAxis);
      let content = `${this.user.firstName} ${this.user.lastName}\n`;
      content += `${this.jobFunctionOrRoleDescription}\n`;
      content += `${this.departmentName}\n`;
      content += `${yAxisDescription.label}: ${this.args.quadrantUser.yAxisPercentage.toFixed(2)}%\n`;
      content += `${xAxisDescription.label}: ${this.args.quadrantUser.xAxisPercentage.toFixed(2)}%`;
      return content;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "axisOptions", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([{
        value: 'compentences',
        label: 'Competências'
      }, {
        value: 'results',
        label: 'Resultados'
      }]);
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "quadrantUser", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "jobFunctions", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "jobRoles", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "departments", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "xAxis", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "yAxis", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "jobFunction", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "jobRole", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "showJobFunction", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "popoverContent", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "popoverContent"), _class.prototype)), _class));
  _exports.default = NineBoxQuadrantUserAvatarComponent;
});