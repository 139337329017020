define("portal-talentrh/router", ["exports", "portal-talentrh/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let Router = (_dec = Ember.inject.service, (_class = class Router extends Ember.Router {
    constructor() {
      super(...arguments);
      _defineProperty(this, "location", _environment.default.locationType);
      _defineProperty(this, "rootURL", _environment.default.rootURL);
      _initializerDefineProperty(this, "permission", _descriptor, this);
      this.on('routeDidChange', () => {
        if (!this.url.includes('have-docs-to-sign/show')) {
          this.permission.getPendingSignatures.perform();
        }
        const url = this.url;
        const env = _environment.default.environment;
        if (!window.gtag || env !== 'production') {
          return;
        }
        gtag('config', 'UA-136622007-1', {
          page_title: url,
          page_path: url
        });
        if (window.companyName) {
          gtag('event', 'set_company', {
            event_label: window.companyName
          });
        }
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Router;
  Router.map(function () {
    this.route('absence-board', function () {
      this.route('create');
      this.route('edit', {
        path: ':user_message_id/edit'
      });
      this.route('future');
      this.route('past');
      this.route('calendar', function () {
        this.route('show', {
          path: ':user_message_id'
        });
      });
    });
    this.route('anniversary');
    this.route('bad-url', {
      path: '/*badurl'
    }); // Catch everything else!

    this.route('company', function () {
      this.route('album', function () {
        this.route('index', {
          path: '/'
        }, function () {
          this.route('new-album');
        });
        this.route('view-album', {
          path: ':company_album_id/view'
        }, function () {
          this.route('edit-album', {
            path: 'edit'
          });
          this.route('new-photo');
        });
      });
      this.route('benefits', function () {
        this.route('details', {
          path: ':benefit_id'
        });
      });
      this.route('chart');
      this.route('decision', function () {
        this.route('edit', {
          path: ':decision_id/edit'
        });
        this.route('index', {
          path: ''
        }, function () {
          this.route('create');
          this.route('vote', {
            path: ':decision_id/vote'
          });
        });
        this.route('show', {
          path: ':decision_id/show'
        });
      });
      this.route('minutes-of-meeting', function () {
        this.route('create');
        this.route('edit', {
          path: ':meeting_minute_id/edit'
        }, function () {
          this.route('edit-topic', {
            path: ':meeting_topic_id/edit'
          });
          this.route('new-topic');
        });
        this.route('index', {
          path: ''
        }, function () {
          this.route('print', {
            path: ':id/print'
          });
          this.route('view', {
            path: ':meeting_minute_id'
          });
        });
      });
      this.route('policy', function () {
        this.route('details', {
          path: '/details/:policy_id'
        });
        this.route('not-accepted', {
          path: '/not-accepted/:policy_id'
        });
      });
      this.route('userlist');
      this.route('job-function');
      this.route('programs', function () {
        this.route('attachments', {
          path: ':company_program_id'
        });
        this.route('viewer', {
          path: ':company_program_attachment_id/viewer'
        });
        this.route('topics', {
          path: ':company_program_id/topics'
        }, function () {
          this.route('attachments', {
            path: ':company_program_topic_id'
          });
          this.route('viewer', {
            path: ':company_program_topic_attachment_id/viewer'
          });
        });
      });
      this.route('professional-progression', function () {
        this.route('index', {
          path: '/'
        });
      });
    });

    // this.route('dashboard');

    this.route('errors', function () {
      this.route('forbidden');
      this.route('inactive-account');
      this.route('not-found');
      this.route('unexpected');
    });
    this.route('external-link', function () {
      this.route('edit', {
        path: ':id/edit'
      });
      this.route('new');
    });
    this.route('feed', function () {
      this.route('index', {
        path: '/'
      }, function () {
        this.route('setup-account', function () {
          this.route('leader', function () {
            this.route('search');
            this.route('suggestion');
          });
          this.route('led');
        });
      });
      this.route('me', function () {
        this.route('annual-income');
        this.route('compensatory-time');
        this.route('error');
        this.route('job-contract');
        this.route('receipt');
        this.route('request', function () {
          this.route('create');
          this.route('show', {
            path: ':user_solicitation_id'
          });
        });
        this.route('time-card');
        this.route('user-expense', function () {
          this.route('extract', {
            path: '/'
          });
          this.route('payroll-discount');
        });
        this.route('schedule');
        this.route('import-receipt');
        this.route('import-time-card');
        this.route('import-annual-incoming');
        this.route('payroll-loan', function () {
          this.route('register-account');
          this.route('complete-account');
          this.route('simulation');
          this.route('index', function () {
            this.route('show-contract', {
              path: ':payroll_loan_id/show-contract'
            });
          });
          this.route('edit-account', {
            path: ':payroll_loan_user_id/edit-account'
          });
        });
        this.route('professional-progression');
        this.route('sent-signatures');
      });
    });
    this.route('forum', function () {
      this.route('index', {
        path: '/'
      }, function () {
        this.route('create');
        this.route('edit', {
          path: ':category_id/edit'
        });
      });
      this.route('topic', {
        path: 'category/:category_id/topic/'
      }, function () {
        this.route('index', {
          path: '/'
        }, function () {
          this.route('create');
          this.route('edit', {
            path: ':topic_id/edit'
          });
        });
        this.route('show', {
          path: ':topic_id'
        });
      });
    });
    this.route('group', function () {
      this.route('show', {
        path: ':user_group_id'
      }, function () {
        this.route('attachment', function () {
          this.route('create');
          this.route('edit', {
            path: ':group_attachment_id/edit'
          });
          this.route('show', {
            path: ':group_attachment_id'
          });
        });
        this.route('members');
      });
      this.route('edit', {
        path: ':user_group_id/edit'
      });
      this.route('create');
    });
    this.route('human-resources', function () {
      this.route('chat-history');
      this.route('feeling');
      this.route('indicators');
      this.route('user-solicitations', function () {
        this.route('historic', function () {});
        this.route('filter');
        this.route('index', {
          path: '/'
        }, function () {});
      });
      this.route('time-card');
      this.route('historic');
      this.route('payment');
      this.route('compensatory-time');
      this.route('indicators');
      this.route('import-time-card');
      this.route('import-receipt');
      this.route('people-analytics');
      this.route('signatures');
      this.route('sent-signatures');
    });
    this.route('login');
    this.route('notification');
    this.route('portal-manager', function () {
      this.route('compensatory-time');
      this.route('indicators');
      this.route('payment');
      this.route('team-members', function () {});
      this.route('request', function () {
        this.route('completed', function () {});
        this.route('hr', function () {});
        this.route('answered', function () {});
        this.route('create');
        this.route('filter');
        this.route('index', {
          path: '/'
        }, function () {});
      });
      this.route('time-card');
      this.route('feeling');
      this.route('import-receipt');
      this.route('import-time-card');
      this.route('historic');
      this.route('people-analytics');
    });
    this.route('indicators', function () {
      this.route('people-analytics', function () {
        this.route('company-profile');
        this.route('human-resources', function () {
          this.route('company-engagement', function () {
            this.route('access-frequency-detail');
            this.route('ranking-influencers-detail');
            this.route('ranking-interactions-detail');
          });
          this.route('company-performance', function () {
            this.route('goals-per-employee', function () {
              this.route('details');
              this.route('collaborator');
            });
            this.route('nine-box-details');
            this.route('pdi-details');
          });
          this.route('company-profile', function () {
            this.route('age-group');
            this.route('average-salary-by-department');
            this.route('average-salary-by-function');
            this.route('distribution-by-function');
            this.route('distribution-by-role');
            this.route('schooling');
            this.route('users-by-company-time');
            this.route('users-by-sex');
          });
          this.route('organizational-climate', function () {
            this.route('prevailing-climate-detail');
            this.route('feelings-negative-positive-detail');
            this.route('feelings-by-type-detail');
            this.route('absences-detail');
          });
        });
        this.route('my-team', function () {
          this.route('organizational-climate', function () {
            this.route('absences-detail');
            this.route('feelings-by-type-detail');
            this.route('feelings-negative-positive-detail');
            this.route('prevailing-climate-detail');
          });
          this.route('company-engagement', function () {
            this.route('access-frequency-detail');
            this.route('ranking-influencers-detail');
            this.route('ranking-interactions-detail');
          });
          this.route('company-performance', function () {
            this.route('goals-per-employee', function () {
              this.route('collaborator');
              this.route('details');
            });
            this.route('nine-box-details');
            this.route('pdi-details');
          });
          this.route('company-profile', function () {
            this.route('age-group');
            this.route('average-salary-by-department');
            this.route('average-salary-by-function');
            this.route('distribution-by-function');
            this.route('distribution-by-role');
            this.route('schooling');
            this.route('users-by-company-time');
            this.route('users-by-sex');
          });
        });
      });
    });
    this.route('post', function () {
      this.route('ad');
      this.route('announcement');
      this.route('attachment', {
        path: ':id/attachment'
      });
      this.route('edit', {
        path: ':post_id/edit'
      });
      this.route('event');
      this.route('presences', {
        path: ':post_id/presences'
      });
      this.route('show', {
        path: ':post_id'
      });
    });
    this.route('vacancy', function () {
      this.route('ad', function () {
        this.route('item', {
          path: ':vacancy_id/item'
        });
      });
    });
    this.route('profile', function () {
      this.route('edit', function () {
        this.route('book', function () {
          this.route('create');
        });
        this.route('corporate');
        this.route('experience', function () {
          this.route('create');
          this.route('edit', {
            path: ':user_experience_id'
          });
        });
        this.route('formation', function () {
          this.route('create');
          this.route('edit', {
            path: ':user_formation_id'
          });
        });
        this.route('index', {
          path: '/'
        }, function () {
          this.route('change-password');
          this.route('change-recover-password-info');
        });
        this.route('signature');
        this.route('professional-interest', function () {
          this.route('create');
          this.route('edit', {
            path: ':user_professional_interest_id'
          });
        });
      });
      this.route('show', {
        path: ':user_id'
      }, function () {
        this.route('information');
        this.route('projects');
        this.route('team', function () {
          this.route('leader');
          this.route('leds');
        });
        this.route('timeline', {
          path: '/'
        });
      });
    });
    this.route('search');

    // Engines
    this.mount('goods');
    this.route('confirm-account-email', {
      path: 'confirm-account-email/:token'
    });
    this.route('insights');
    this.route('view-team-member', {
      path: 'view-team-member/:user_id'
    }, function () {
      this.route('notes', function () {
        this.route('create');
      });
    });

    // this.route('dashboard', function() {
    //   this.route('show');
    // });
    this.route('confirm-account-email', {
      path: 'confirm-account-email/:token'
    });
    this.route('view-team-member', {
      path: 'view-team-member/:user_id'
    }, function () {
      this.route('notes', function () {
        this.route('create');
        this.route('edit', {
          path: ':note_id/edit'
        });
      });
      this.route('professional-profile');
      this.route('performance-management', function () {
        this.route('index', {
          path: '/job-skills'
        });
        this.route('goals');
        this.route('pdi');
        this.route('feedback');
        this.route('employee-evaluations', function () {
          this.route('answers', {
            path: ':evaluation_id/answers'
          });
        });
      });
      this.route('historical-data');
      this.route('projects', function () {
        this.route('active');
        this.route('concluded');
      });
      this.route('feelings');
      this.route('receipt');
      this.route('timecard');
      this.route('schedule');
    });
    this.route('habitus', function () {
      this.route('preferences', {
        path: ':habitus_preference_id'
      });
      this.route('news');
    });
    this.route('privacy-policy');
    this.route('home', {
      path: '/'
    }, function () {
      this.route('human-resources', function () {
        this.route('open-vacations');
        this.route('compensatory-times');
        this.route('late-task');
        this.route('experience-contract');
        this.route('feedback-details');
        this.route('pdi-details');
        this.route('team-climate-details');
        this.route('goal-details', function () {
          this.route('collaborator');
        });
        this.route('time-card-details');
        this.route('custom-goals-details', function () {
          this.route('collaborator');
        });
      });
      this.route('my-team', function () {
        this.route('pdi-details');
        this.route('feedback-details');
        this.route('team-climate-details');
        this.route('open-vacations');
        this.route('experience-contract');
        this.route('late-task');
        this.route('compensatory-times');
        this.route('goal-details', function () {
          this.route('collaborator');
        });
        this.route('custom-goals-details', function () {
          this.route('collaborator');
        });
        this.route('time-card-details');
      });
      this.route('my-workspace');
    });
    this.route('have-docs-to-sign', function () {
      this.route('show-time-card');
      this.route('show-import-time-card');
      this.route('show-receipt');
      this.route('show-import-receipt');
    });
  });
});