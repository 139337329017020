define("portal-talentrh/helpers/linkify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.linkify = linkify;
  function linkify(_ref) {
    let [text] = _ref;
    const urlPattern = /(\()?https?:\/\/[^\s()]+(\))?/g;
    const boldPattern = /\*\*(.*?)\*\*/g;
    let formattedText = text.replace(urlPattern, (match, p1, p3) => {
      const url = match.replace(/[()]/g, '');
      let linkText = 'Link';
      if (p1 && p3) {
        return `(<a href="${url}" target="_blank" style="color: blue; text-decoration: underline;">${linkText}</a>)`;
      } else if (p1) {
        return `(<a href="${url}" target="_blank" style="color: blue; text-decoration: underline;">${linkText}</a>`;
      } else if (p3) {
        return `<a href="${url}" target="_blank" style="color: blue; text-decoration: underline;">${linkText}</a>)`;
      } else {
        return `<a href="${url}" target="_blank" style="color: blue; text-decoration: underline;">${linkText}</a>`;
      }
    });
    formattedText = formattedText.replace(boldPattern, '<strong>$1</strong>');
    return formattedText;
  }
  var _default = Ember.Helper.helper(linkify);
  _exports.default = _default;
});