define("portal-talentrh/pods/habitus/index/chat-ia-message/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ChatIaMessageComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, (_class = class ChatIaMessageComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "session", _descriptor, this);
      _initializerDefineProperty(this, "permission", _descriptor2, this);
      _initializerDefineProperty(this, "fetch", _descriptor3, this);
      _initializerDefineProperty(this, "page", _descriptor4, this);
      _initializerDefineProperty(this, "loadingMore", _descriptor5, this);
      _initializerDefineProperty(this, "sendMessages", _descriptor6, this);
      _initializerDefineProperty(this, "userMessage", _descriptor7, this);
      _initializerDefineProperty(this, "messages", _descriptor8, this);
      _initializerDefineProperty(this, "isWaitingForResponse", _descriptor9, this);
      _initializerDefineProperty(this, "selectedContact", _descriptor10, this);
    }
    start() {
      try {
        const {
          messages
        } = this.args;
        this.messages = messages.chatMessages;
        if (this.messages.length !== 0) {
          this.scrollToLastMessage(this.messages.get('lastObject').id);
        }
      } catch (e) {
        Ember.debug(`Habitus :: ChatIA :: start :: ${e}`);
      }
    }
    updateUserMessage(event) {
      this.userMessage = event.target.value;
    }
    handleScroll(event) {
      let element = event.target;
      if (element.scrollTop === 0 && !this.loadingMore) {
        this.page++;
        this.loadingMore = true;
        this.previousScrollHeight = element.scrollHeight;
        this.loadMoreMessages();
      }
    }
    async loadMoreMessages() {
      let chatContainer = document.getElementById('chat-container');
      try {
        this.fetch.request(`/getChatMessages/${this.selectedContact.id}/${this.page}`).then(response => {
          if (response.chatMessages && response.chatMessages.length) {
            this.messages = [...response.chatMessages, ...this.messages];
            setTimeout(() => {
              chatContainer.scrollTop = chatContainer.scrollHeight - this.previousScrollHeight;
            }, 0);
          }
          this.loadingMore = false;
        });
      } catch (e) {
        this.loadingMore = false;
        Ember.debug(`Habitus :: ChatIA :: loadMoreMessages :: ${e}`);
      }
    }
    handleKeyDown(event) {
      if (event.key === 'Enter') {
        event.preventDefault();
        this.sendUserMessage();
      }
    }
    async sendUserMessage() {
      if (!this.userMessage.trim()) return;
      let sessionId;
      if (this.messages.length > 0) {
        sessionId = this.messages.get('lastObject').sessionId;
      }
      let newMessage = {
        "messageId": -1,
        "repositoryId": this.selectedContact.id.toString(),
        "userId": this.session.user.id,
        "company": this.selectedContact.company,
        "userMessage": this.userMessage,
        "iaMessage": "",
        "prompt": this.selectedContact.prompt,
        "version": this.selectedContact.version,
        "temperature": this.selectedContact.temperature,
        "indexName": this.selectedContact.indexName,
        "createdAt": new Date(),
        "sessionId": sessionId
      };
      this.isWaitingForResponse = true;
      this.messages = [...this.messages, newMessage];
      if (this.messages.length) {
        this.scrollToLastMessage('-1');
      }
      this.args.sendMessages(newMessage).then(r => {
        this.messages = this.messages.map(msg => msg.messageId === newMessage.messageId ? r : msg);
        this.isWaitingForResponse = false;
        this.scrollToLastMessage(r.id);
      });
      this.userMessage = '';
    }
    scrollToLastMessage(messageId) {
      Ember.run.schedule('afterRender', this, () => {
        let attempts = 0;
        const attemptScroll = () => {
          const element = document.getElementById(messageId);
          if (element) {
            element.scrollIntoView({
              behavior: 'smooth'
            });
          } else if (attempts < 3) {
            attempts++;
            setTimeout(attemptScroll, 10);
          }
        };
        attemptScroll();
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "page", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "loadingMore", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "sendMessages", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "userMessage", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "messages", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "isWaitingForResponse", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "selectedContact", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.selectedContact;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "start", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "start"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateUserMessage", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "updateUserMessage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleScroll", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "handleScroll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadMoreMessages", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "loadMoreMessages"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleKeyDown", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "handleKeyDown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sendUserMessage", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "sendUserMessage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "scrollToLastMessage", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "scrollToLastMessage"), _class.prototype)), _class));
  _exports.default = ChatIaMessageComponent;
});