define("portal-talentrh/models/job-role", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    title: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    visible: (0, _attr.default)('boolean'),
    company: (0, _relationships.belongsTo)('company'),
    categories: (0, _relationships.hasMany)('category', {
      inverse: 'jobroles'
    })
  });
  _exports.default = _default;
});