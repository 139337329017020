define("portal-talentrh/pods/have-docs-to-sign/controller", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let HaveDocsToSignController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember.computed.alias('model.competences'), _dec10 = Ember._tracked, _dec11 = Ember.computed.alias('permission.docsToSign'), _dec12 = Ember.computed.alias('permission.routeHome'), _dec13 = Ember.computed('docsToSign'), _dec14 = Ember.computed('docsToSign'), (_class = class HaveDocsToSignController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "fetch", _descriptor, this);
      _initializerDefineProperty(this, "permission", _descriptor2, this);
      _initializerDefineProperty(this, "router", _descriptor3, this);
      _initializerDefineProperty(this, "swal", _descriptor4, this);
      _initializerDefineProperty(this, "toast", _descriptor5, this);
      _initializerDefineProperty(this, "competence", _descriptor6, this);
      _initializerDefineProperty(this, "docToSign", _descriptor7, this);
      _initializerDefineProperty(this, "timeCard", _descriptor8, this);
      _initializerDefineProperty(this, "competences", _descriptor9, this);
      _initializerDefineProperty(this, "showModal", _descriptor10, this);
      _initializerDefineProperty(this, "docsToSign", _descriptor11, this);
      _initializerDefineProperty(this, "routeHome", _descriptor12, this);
    }
    get deferIsAvailable() {
      return this.docsToSign.some(doc => doc.isPossibleToDefer);
    }
    get deferDate() {
      const [doc] = this.docsToSign;
      return doc.deferDate;
    }
    *defer(docsToSign) {
      try {
        const timeCards = docsToSign.filter(doc => doc.typeDocument === 'timecard');
        const paymentReceipts = docsToSign.filter(doc => doc.typeDocument === 'paymentReceipt');
        const documents = {
          timeCards,
          paymentReceipts
        };
        const deferredDocuments = yield this.fetch.request('deferSignDocuments', {
          method: 'POST',
          data: {
            documents
          }
        });
        this.permission.set('docsToSign', deferredDocuments);
        this.router.transitionTo(this.routeHome);
      } catch (e) {
        return this.swal.catch(e);
      }
    }
    *showDocument(document) {
      const {
        readDocument
      } = yield this.fetch.request('markDocumentAsRead', {
        method: 'POST',
        data: {
          document
        }
      });
      document.alreadyRead = readDocument.alreadyRead;
      this.docToSign = document;
      this.competence = document.competence;
      this.permission.set('showDocument', true);
      this.permission.set('docToSign', document);
      this.showModal = false;
      const {
        fromIntegrationApi
      } = document;
      if (document.typeDocument === 'timecard') {
        if (fromIntegrationApi) {
          return this.router.replaceWith('have-docs-to-sign.show-time-card');
        }
        return this.router.replaceWith('have-docs-to-sign.show-import-time-card');
      }
      if (fromIntegrationApi) {
        return this.router.replaceWith('have-docs-to-sign.show-receipt');
      }
      return this.router.replaceWith('have-docs-to-sign.show-import-receipt');
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "competence", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "docToSign", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "timeCard", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "competences", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "showModal", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "docsToSign", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "routeHome", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "deferIsAvailable", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "deferIsAvailable"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deferDate", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "deferDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "defer", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "defer"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showDocument", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "showDocument"), _class.prototype)), _class));
  _exports.default = HaveDocsToSignController;
});