define("portal-talentrh/pods/forum/topic/index/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "P7rks810",
    "block": "{\"symbols\":[\"Modal\",\"Footer\"],\"statements\":[[8,\"ui-modal\",[[24,\"data-backdrop\",\"static\"]],[[\"@show\",\"@size\"],[true,\"lg\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[8,[32,1,[\"Body\"]],[[24,0,\"overflow-auto ui-scrollbar max-h-vh-8\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n      \"],[10,\"label\"],[12],[2,\"Título\"],[13],[2,\"\\n      \"],[8,\"input\",[[24,0,\"form-control\"],[24,\"placeholder\",\"Informe um título\"]],[[\"@value\"],[[32,0,[\"topic\",\"title\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"col-lg-12\"],[12],[2,\"\\n      \"],[10,\"label\"],[12],[2,\"Descrição\"],[13],[2,\"\\n       \"],[10,\"div\"],[14,0,\"form-group label-floating z-index-0\"],[12],[2,\"\\n         \"],[8,\"nela-editor\",[],[[\"@input\",\"@text\",\"@images\",\"@videos\",\"@canInsertImage\",\"@canInsertVideo\",\"@maxVideoFileSizeInMB\"],[[30,[36,1],[[30,[36,0],[[32,0,[\"topic\",\"body\"]]],null]],null],[32,0,[\"topic\",\"body\"]],[32,0,[\"advancedEditorImages\"]],[32,0,[\"advancedEditorVideos\"]],true,true,500]],null],[2,\"\\n       \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Footer\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"Close\"]],[[16,\"onclick\",[30,[36,2],[[32,0,[\"closeModal\"]]],null]]],[[],[]],null],[2,\"\\n    \"],[8,[32,2,[\"Submit\"]],[[16,\"onclick\",[30,[36,2],[[32,0,[\"save\"]]],null]]],[[],[]],null],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"mut\",\"fn\",\"perform\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/forum/topic/index/edit/template.hbs"
    }
  });
  _exports.default = _default;
});