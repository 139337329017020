define("portal-talentrh/pods/absence-board/board/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ember-concurrency"], function (_exports, _component, _emberConcurrencyDecorators, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let BoardComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember.computed.alias('permission.led'), _dec12 = Ember.computed('args'), _dec13 = Ember.computed('args.from'), _dec14 = Ember.computed('args.filters', 'filters'), _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, _dec20 = Ember._action, (_class = class BoardComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "session", _descriptor2, this);
      _initializerDefineProperty(this, "swal", _descriptor3, this);
      _initializerDefineProperty(this, "permission", _descriptor4, this);
      _initializerDefineProperty(this, "search", _descriptor5, this);
      _initializerDefineProperty(this, "initialDate", _descriptor6, this);
      _initializerDefineProperty(this, "endDate", _descriptor7, this);
      _initializerDefineProperty(this, "isNotFiltered", _descriptor8, this);
      _initializerDefineProperty(this, "ledType", _descriptor9, this);
      _initializerDefineProperty(this, "filters", _descriptor10, this);
      _initializerDefineProperty(this, "led", _descriptor11, this);
    }
    get getUrlAbsence() {
      const {
        from
      } = this.args;
      return from === 'my-team' ? '/api/leader/getAbsences' : '/api/getAbsences';
    }
    get isMyTeam() {
      const {
        from
      } = this.args;
      return from === 'my-team';
    }
    get getFilterChanged() {
      const {
        filters
      } = this.args;
      return {
        ...filters,
        ...this.filters
      };
    }
    *filterTerm() {
      yield (0, _emberConcurrency.timeout)(300);
      this.filterDate();
    }
    clearFilter() {
      this.isNotFiltered = true;
      this.search = null;
      this.initialDate = null;
      this.endDate = null;
      this.args.getFilters(this.search);
    }
    filterDate() {
      const startDateToFilter = this.initialDate;
      const endDateToFilter = this.endDate;
      this.isNotFiltered = this.initialDate || this.endDate || this.search ? false : true;
      if (startDateToFilter && endDateToFilter && startDateToFilter > endDateToFilter) {
        this.swal.warning('A data final não pode ser anterior a data inicial');
        this.isNotFiltered = true;
        return;
      }
      this.args.getFilters(this.search, {
        startDateToFilter,
        endDateToFilter
      });
    }
    setInitialDate(_ref) {
      let {
        date
      } = _ref;
      this.initialDate = date;
      this.filterDate();
    }
    setEndDate(_ref2) {
      let {
        date
      } = _ref2;
      this.endDate = date;
      this.filterDate();
    }
    onChangeFilter() {
      this.search = null;
      this.initialDate = null;
      this.endDate = null;
      this.args.getFilters(this.search);
    }
    getFilters() {
      const {
        filters
      } = this.args;
      const filter = {};
      if (this.ledType) {
        filter.ledType = this.ledType;
      }
      this.filters = {
        ...filters,
        ...filter
      };
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "initialDate", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "endDate", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isNotFiltered", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "ledType", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'direct';
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        ledType: 'direct'
      };
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "led", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getUrlAbsence", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "getUrlAbsence"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isMyTeam", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "isMyTeam"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getFilterChanged", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "getFilterChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterTerm", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "filterTerm"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearFilter", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "clearFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterDate", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "filterDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setInitialDate", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "setInitialDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setEndDate", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "setEndDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeFilter", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getFilters", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "getFilters"), _class.prototype)), _class));
  _exports.default = BoardComponent;
});