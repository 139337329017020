define("portal-talentrh/pods/feed/me/index/goals/items/exclusive-goal/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let FeedMeIndexGoalsItemsExclusiveGoalComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember.computed('permission.user', 'args.user'), _dec9 = Ember.computed('permission.user', 'args.user'), _dec10 = Ember._action, _dec11 = Ember._action, (_class = class FeedMeIndexGoalsItemsExclusiveGoalComponent extends _component.default {
    // Default values

    // Multi line computed
    get user() {
      if (this.args.user) return this.args.user;
      return this.permission.user;
    }
    get urlCustomGoals() {
      if (!this.args.user) return `/customGoals/byUser`;
      return '/customGoals';
    }
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "permission", _descriptor, this);
      _initializerDefineProperty(this, "intro", _descriptor2, this);
      _initializerDefineProperty(this, "swal", _descriptor3, this);
      _initializerDefineProperty(this, "fetch", _descriptor4, this);
      _initializerDefineProperty(this, "exclusiveGoals", _descriptor5, this);
      _initializerDefineProperty(this, "competences", _descriptor6, this);
      _initializerDefineProperty(this, "competenceSelected", _descriptor7, this);
      this.loadCompetences.perform();
    }

    // methods
    _getQualitativeGoalWithProgressBarProperties(exclusiveGoal) {
      const percentLabel = exclusiveGoal.valueAchieved === 'S' ? 'Meta atingida' : 'Meta não atingida';
      const processBarColor = exclusiveGoal.valueAchieved === 'S' ? 'bg-info' : 'bg-not-achieved';
      return {
        ...exclusiveGoal,
        percent: '100%',
        processBarColor,
        percentLabel,
        centerLabel: true
      };
    }
    _getGoalWithProgressBarProperties(exclusiveGoal) {
      let percent = `${exclusiveGoal.percentage}%`;
      let percentLabel = percent;
      let centerLabel = false;
      let processBarColor = 'bg-info';
      if (exclusiveGoal.unitType === 'between') {
        percentLabel = percent === 100 ? 'Meta atingida' : 'Meta não atingida';
        processBarColor = percent === 100 ? 'bg-info' : 'bg-not-achieved';
        percent = '100%';
        centerLabel = true;
      }
      return {
        ...exclusiveGoal,
        percent,
        percentLabel,
        processBarColor,
        centerLabel
      };
    }
    _setProgressBarProperties(exclusiveGoal) {
      if (exclusiveGoal.unit === 'qualitative') {
        return this._getQualitativeGoalWithProgressBarProperties(exclusiveGoal);
      }
      return this._getGoalWithProgressBarProperties(exclusiveGoal);
    }
    getQualitativeValues(goal) {
      const percentLabel = goal.valueAchieved === 'S' ? 'Meta atingida' : 'Meta não atingida';
      const processBarColor = goal.valueAchieved === 'S' ? 'bg-info' : 'bg-not-achieved';
      return {
        ...goal,
        percent: '100%',
        processBarColor,
        percentLabel,
        centerLabel: true
      };
    }

    // Tasks
    *loadExclusiveGoal() {
      try {
        if (!this.competenceSelected) {
          return;
        }
        const {
          competenceStart,
          competenceEnd
        } = this.competenceSelected;
        const user = this.user;
        const exclusiveGoals = yield this.fetch.request(this.urlCustomGoals, {
          data: {
            competenceStart,
            competenceEnd,
            userIds: [user.id]
          }
        });
        this.exclusiveGoals = exclusiveGoals.map(exclusiveGoal => {
          return this._setProgressBarProperties(exclusiveGoal);
        });
      } catch (e) {
        if (e.errors && e.errors.firstObject.status === 404) {
          this.exclusiveGoals = [];
          return;
        }
        this.swal.catch(e);
      }
    }
    *loadCompetences() {
      try {
        const competences = yield this.fetch.request(`/customGoals/competences`, {
          data: {
            userId: this.user.id
          }
        });
        competences.forEach(competence => {
          competence.label = `${competence.startAt}`;
          if (competence.endAt) {
            competence.label += ` - ${competence.endAt}`;
          }
        });
        this.competences = competences;
        this.competenceSelected = competences[0];
        yield this.loadExclusiveGoal.perform();
      } catch (e) {
        if (e.errors && e.errors.firstObject.status === 404) {
          this.exclusiveGoals = [];
          return;
        }
        this.swal.catch(e);
      }
    }
    // Actions
    setProgressBarWidth(el) {
      el?.setAttribute('style', 'width:0');
      setTimeout(() => {
        el?.setAttribute('style', `width:${el.getAttribute('aria-valuenow')}`);
      }, 10);
    }
    onCompetenceSelected(competence) {
      this.competenceSelected = competence;
      this.loadExclusiveGoal.perform();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intro", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "exclusiveGoals", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "competences", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "competenceSelected", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "user", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "user"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "urlCustomGoals", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "urlCustomGoals"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadExclusiveGoal", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadExclusiveGoal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadCompetences", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadCompetences"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setProgressBarWidth", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "setProgressBarWidth"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCompetenceSelected", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "onCompetenceSelected"), _class.prototype)), _class));
  _exports.default = FeedMeIndexGoalsItemsExclusiveGoalComponent;
});